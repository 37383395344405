"use client";

import Link from "next/link";
import * as React from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import {
  Menu,
  Info,
  Wrench,
  MapPin,
  Phone,
  BriefcaseBusiness,
  Book,
  HeartHandshake,
  FileCheck2,
  Bolt,
  Cog,
  Caravan,
  BusFront,
  LayoutDashboard,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ScrollArea } from "./ui/scroll-area";

const components = [
  {
    title: "Full Service Repair",
    href: "/services",
    description:
      "Our full service shop can take on any repairs, no matter how big or small.",
  },
  {
    title: "Mobile & Quick Turn Repair",
    href: "/services/mobile",
    description:
      "Our mobile repair service is there for those on the go. Quick turn repairs can be completed upon arrival.",
  },
  {
    title: "Trailer Repair",
    href: "/services/trailer",
    description:
      "From suspension to mud flaps, we got you covered for all your trailer's mechanical repairs.",
  },
  {
    title: "RV Repair",
    href: "/services/rv",
    description: "Getting ready for that vacation? We can help get you there.",
  },
];

export function NavMenu() {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="bg-transparent">
            About Us
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col justify-center rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                    href="/vandi"
                  >
                    <Image
                      height={40}
                      width={100}
                      src={"/VandiLogo.png"}
                      alt="Vandi"
                      className="mb-4"
                    />
                    <p className="text-sm leading-tight text-white/70">
                      Creating a better way to implement repair transparency.
                    </p>
                  </a>
                </NavigationMenuLink>
              </li>
              <ListItem href="/about" title="Our Story">
                Want to know how we started? Look no further.
              </ListItem>
              <ListItem href="/terms" title="Terms of Service">
                These are the terms of our service. Warranty and refund
                information can be found here.
              </ListItem>
              <ListItem href="/privacy" title="Privacy Policy">
                Customer privacy is very important to us. View our policy here.
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="bg-transparent">
            Services
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {components.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/locations" legacyBehavior passHref>
            <NavigationMenuLink
              className={`${navigationMenuTriggerStyle()} bg-transparent`}
            >
              Locations
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/careers" legacyBehavior passHref>
            <NavigationMenuLink
              className={`${navigationMenuTriggerStyle()} bg-transparent`}
            >
              Careers
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/contact" legacyBehavior passHref>
            <NavigationMenuLink
              className={`${navigationMenuTriggerStyle()} bg-transparent`}
            >
              Contact Us
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </a>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";

export function MobileNav() {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="icon" size="icon" className="h-12 w-12" aria-label="Navigation Menu">
          <Menu className="h-6 w-6" />
        </Button>
      </SheetTrigger>
      <SheetContent autoFocus={false}>
        <SheetHeader>
          <SheetTitle>Navigation</SheetTitle>
          <SheetDescription>Where would you like to go?</SheetDescription>
        </SheetHeader>
        <ScrollArea className="h-[calc(100vh-80px)] w-full">

        <Accordion type="single" collapsible className="mt-4">
          <AccordionItem value="about" className="border-0">
            <AccordionTrigger className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900">
              <div className="flex items-center gap-x-4 py-3.5">
                <Info className="h-4 w-4" />
                About Us
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <SheetClose asChild>
                <Link
                  href="/about"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <Book className="h-4 w-4" />
                    Our Story
                  </div>
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link
                  href="/vandi"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <LayoutDashboard className="h-4 w-4" />
                    Vandi
                  </div>
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link
                  href="/privacy"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <HeartHandshake className="h-4 w-4" />
                    Privacy Policy
                  </div>
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link
                  href="/terms"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <FileCheck2 className="h-4 w-4" />
                    Terms of Service
                  </div>
                </Link>
              </SheetClose>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="services" className="border-0">
            <AccordionTrigger className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900">
              <div className="flex items-center gap-x-4 py-3.5">
                <Wrench className="h-4 w-4" />
                Services
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <SheetClose asChild>
                <Link
                  href="/services"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <Bolt className="h-4 w-4" />
                    Vantage Diesel
                  </div>
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link
                  href="/services/xpress"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <Cog className="h-4 w-4" />
                    Vantage Diesel Xpress
                  </div>
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link
                  href="/services/trailer"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <Caravan className="h-4 w-4" />
                    Trailer Repair
                  </div>
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link
                  href="/services/rv"
                  className="focus:no-underline active:no-underline hover:no-underline font-bold flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
                >
                  <div className="flex items-center gap-x-4 py-3.5 pl-4">
                    <BusFront className="h-4 w-4" />
                    RV Repair
                  </div>
                </Link>
              </SheetClose>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem
            value="locations"
            className="focus:no-underline active:no-underline hover:no-underline border-0 font-bold"
          >
            <SheetClose asChild>
              <Link
                href="/locations"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
              >
                <div className="flex items-center gap-x-4 py-3.5 text-sm">
                  <MapPin className="h-4 w-4" />
                  Locations
                </div>
              </Link>
            </SheetClose>
          </AccordionItem>
          <AccordionItem
            value="careers"
            className="focus:no-underline active:no-underline hover:no-underline border-0 font-bold"
          >
            <SheetClose asChild>
              <Link
                href="/careers"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
              >
                <div className="flex items-center gap-x-4 py-3.5 text-sm">
                  <BriefcaseBusiness className="h-4 w-4" />
                  Careers
                </div>
              </Link>
            </SheetClose>
          </AccordionItem>
          <AccordionItem
            value="careers"
            className="focus:no-underline active:no-underline hover:no-underline border-0 font-bold"
          >
            <SheetClose asChild>
              <Link
                href="/contact"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-900"
              >
                <div className="flex items-center gap-x-4 py-3.5 text-sm">
                  <Phone className="h-4 w-4" />
                  Contact Us
                </div>
              </Link>
            </SheetClose>
          </AccordionItem>
        </Accordion>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
