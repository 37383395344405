"use client";

export const keywords = [
  "affordable",
  "CAT",
  "reliable",
  "Peterbilt",
  "ISX",
  "mechanical",
  "Dodge",
  "mobile",
  "DD15",
  "quick",
  "Volvo",
  "professional",
  "Chevrolet",
  "brake",
  "X15",
  "engine",
  "Kenworth",
  "medium duty",
  "Paccar",
  "alternator",
  "N14",
  "fuel system",
  "DD13",
  "electrical",
  "MaxxForce",
  "3406E",
  "hydraulic",
  "Mack",
  "suspension",
  "trailer",
  "3176",
  "Freightliner",
  "HVAC",
  "tire",
  "Ford",
  "starter",
  "DT466",
  "radiator",
  "Isuzu",
  "emissions",
  "International",
  "heavy duty",
  "battery",
  "Hino",
  "preventative",
  "GMC",
];
