import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["keywords"] */ "/home/vantagediesel.com/vantage-diesel/app/keywords.js");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNav","NavMenu"] */ "/home/vantagediesel.com/vantage-diesel/components/navigation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/vantagediesel.com/vantage-diesel/components/theme-provider.jsx");
;
import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/vantagediesel.com/vantage-diesel/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"inter\"}");
